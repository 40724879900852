import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import Layout from "src/components/core/layout/layout"
import Card from "src/components/core/card"
import styled from "styled-components"
import Spacer from "src/components/core/spacer"
import Avatar from "src/components/core/avatar"
import CardContent from "src/components/core/card-content"
import shadow from "src/components/core/theme/shadows"
import Img from "gatsby-image/withIEPolyfill"

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      data {
        title {
          html
          text
        }
        portrait {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        photo {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        bio {
          html
        }
        twitter {
          url
        }
        instagram {
          url
        }
        facebook {
          url
        }
      }
    }
    prismicAboutsidebar {
      data {
        text {
          html
        }
      }
    }
    allPrismicBook {
      nodes {
        uid
        data {
          title {
            text
          }
          link {
            raw {
              url
            }
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`
const FloatingAvatar = styled(Avatar)`
flex-shrink: 0;
  /* width: 5rem; */
  /* height: 5rem; */
  margin: auto;
  border-radius: 8px;
  @media (min-width: 576px) {
    margin: 1rem 1rem 1rem 0;
  }
`
const AboutWrap = styled.div`
    display: flex;
    flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
  }
`
const CoverPreview = styled.div``
const PreviewTitle = styled.h3``
const PreviewCoverWrap = styled.div``

const About = ({ data, className }) => {
  const portrait =
  data.prismicAbout.data.portrait ? data.prismicAbout.data.portrait.localFile.childImageSharp.fluid : null
  const sidebar = data.prismicAboutsidebar.data.text ? data.prismicAboutsidebar.data.text.html : null
  const covers = []
  data.allPrismicBook.nodes.forEach(book =>
    covers.push(
      <CoverPreview>
        <a href={book.data.link ? book.data.link.raw.url : null}>
          <PreviewTitle>{book.data.title ? book.data.title.text : null}</PreviewTitle>
          <PreviewCoverWrap>
            <Img fluid={book.data.cover && book.data.cover.localFile && book.data.cover.localFile.childImageSharp ? book.data.cover.localFile.childImageSharp.fluid : null} />
          </PreviewCoverWrap>
        </a>
      </CoverPreview>
    )
  )
  const aboutSidebar = `
  <div>Read more about the <a href="/books">Flavian trilogy</a></div>
  <div>Read some of  <a href="/posts">my posts</a></div>`
  const [loaded, setLoaded] = useState(false)
  useEffect(()=>setLoaded(true), [])
return !loaded ? <Layout></Layout> : (
    <Layout 
    title='About Anthony Jennings'
    sidebar={sidebar} 
    toptags={false}>
      <Spacer />
      <Card>
        <CardContent>
        <AboutWrap>
          <FloatingAvatar
            className={className}
            photo={portrait ? portrait : null}
            dS={"10rem"}
            dL={"15rem"}
            bp={"576px"}
          />
          <div className="justifiedtext"
            dangerouslySetInnerHTML={{
              __html: data.prismicAbout.data.bio ? data.prismicAbout.data.bio.html : null,
            }}
          />
        </AboutWrap>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default About
